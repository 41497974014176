@import-normalize;

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
       url('./fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('./fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('./fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'SportingGrotesque';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Sporting_Grotesque-Regular_web.eot');
  src: local('SportingGrotesque Regular'), local('SportingGrotesque-Regular'),
       url('./fonts/Sporting_Grotesque-Regular_web.eot?#iefix') format('embedded-opentype'),
       url('./fonts/Sporting_Grotesque-Regular_web.woff2') format('woff2'),
       url('./fonts/Sporting_Grotesque-Regular_web.woff') format('woff'),
       url('./fonts/Sporting_Grotesque-Regular_web.ttf') format('truetype');
}

@font-face {
    font-family: 'Volte Regular Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Volte Regular Italic'), url('./fonts/Volte Regular Italic.woff') format('woff');
}

@font-face {
    font-family: 'Volte Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Volte Regular'), url('./fonts/Volte.woff') format('woff');
}

@font-face {
    font-family: 'Volte Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Volte Light Italic'), url('./fonts/Volte Light Italic.woff') format('woff');
}

@font-face {
    font-family: 'Volte Light';
    font-style: normal;
    font-weight: normal;
    src: local('Volte Light'), url('./fonts/Volte Light.woff') format('woff');
}

@font-face {
    font-family: 'Volte Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Volte Medium Italic'), url('./fonts/Volte Medium Italic.woff') format('woff');
}

@font-face {
    font-family: 'Volte Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Volte Medium'), url('./fonts/Volte Medium.woff') format('woff');
}

@font-face {
    font-family: 'Volte Semibold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Volte Semibold Italic'), url('./fonts/Volte Semibold Italic.woff') format('woff');
}

@font-face {
    font-family: 'Volte Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('Volte Semibold'), url('./fonts/Volte Semibold.woff') format('woff');
}

@font-face {
    font-family: 'Volte Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Volte Bold Italic'), url('./fonts/Volte Bold Italic.woff') format('woff');
}

@font-face {
    font-family: 'Volte Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Volte Bold'), url('./fonts/Volte Bold.woff') format('woff');
}

html, body {
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.modal-open {
  overflow-y: hidden;
  position: fixed;
  left: 0;
  right: 0;
}

#root {
  width: 100%;
  height: 100%;
  background-color: white;
}

.control-dots {
  padding: 0;
}

@media (max-width: 800px) {
  .mobile-break {
    display: block
  }
}

.slick-track {
  display: flex !important;
  align-items: center;
}

.slick-slide {
  height: inherit !important;
}
.slick-slide > div {
  height: inherit;
}
.slick-list, .slick-track {
  height: 100% !important;
}

